/* eslint-disable react/prefer-stateless-function */
import React from "react";

import { Link } from "gatsby";

import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import linkedin from "../img/social/linkedin.svg";
import playstore from "../img/social/play-store.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src="/img/VITspot-logo.png"
            alt="VITspot"
            style={{ height: "5em" }}
          />
          <p>Made with ♥ for VITians</p>
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/posts">
                        Latest Stories
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/dmca">
                        DMCA
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a
                  title="Facebook"
                  href="https://facebook.com/vitspot"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="Instagram"
                  href="https://instagram.com/vitspot"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="Linkedin"
                  href="https://www.linkedin.com/company/vitspot"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={linkedin}
                    alt="Linkedin"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="Play Store"
                  href="https://play.google.com/store/apps/details?id=com.vitspot.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={playstore}
                    alt="Play Store"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="px-6">
          <p className="is-size-7 has-text-centered has-text-weight-light">
            Copying Content/Ideas of VITspot without express and written
            permission from this site’s owner is strictly prohibited. Excerpts
            and links may be used, provided that full and clear credit is given
            to VITspot with appropriate and specific direction to the original
            content.
          </p>
          <p className="is-size-7 has-text-centered has-text-weight-light">
            We are not associated with{" "}
            <span className="has-text-weight-bold">VIT</span> in any way. All
            information provided in this website is for general information only
            and is not official information from{" "}
            <span className="has-text-weight-bold">VIT</span>.
          </p>
          <p className="is-size-7 has-text-centered has-text-weight-bold">
            Copyright © 2017-{new Date().getFullYear()} VITspot Network
          </p>
          <br />
        </div>
      </footer>
    );
  }
};

export default Footer;
